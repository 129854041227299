import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { defineMessages, useIntl } from 'react-intl';
import { ArrayWidget } from '@plone/volto/components';
import { ps_list } from './utils';

const messages = defineMessages({
  ps: {
    id: 'ps_times_ps',
    defaultMessage: 'Pronto soccorso',
  },
});

const Sidebar = ({ block, data, onChangeBlock }) => {
  const intl = useIntl();

  return (
    <Segment.Group raised key={block.id || block}>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="ps_times"
            defaultMessage="Tempi Pronto Soccorso"
          />
        </h2>
      </header>
      <Segment>
        <div className="ui form">
          <ArrayWidget
            id="ps"
            title={intl.formatMessage(messages.ps)}
            value={data.ps}
            onChange={(id, value) => {
              onChangeBlock(block, {
                ...data,
                [id]: value,
              });
            }}
            choices={ps_list}
          />
        </div>
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  block: PropTypes.string,
  selected: PropTypes.any,
  setSelected: PropTypes.func,
};

export default injectIntl(Sidebar);
